<template>
   <div :class="{'list-item is-flex':true,'list-item-to-be-deleted':nextSwipeTriggersRemove}" ref="root" v-touch:swipe.right="handleSwipeRight" v-touch:swipe.left="handleSwipeLeft">
        <div style="height:6rem;width:6rem">
          <img class="list-item-img" v-if="okay2load && item.pictures?.length > 0"
            :src="item.pictures[0]" 
            alt="image of item"
          >
          
        </div>
        
        <div class="list-item-content-container ml-1 is-full-height is-flex is-flex-direction-column is-justify-content-space-around is-clipped">
          <h3 class="m-1 mb-2 is-overflow-x-scroll  is-overflow-y-hidden has-no-scrollbars" style="text-align:left; white-space:nowrap;">{{item.name}}</h3>
          <p 
            :class="{'has-background-green-3 has-text-white is-half-width is-size-7 ml-1 is-float-left':true,'has-background-orange-2':itemLendingDirection(item,$store) == 'LENT'}" 
            v-if="item.possessedby && item.possessedby.length > 0">
                  {{ itemLendingDirection(item,$store) }}
          </p>
          <div v-if="itemContainersComplete" class="m-1 is-flex is-flex-direction-row is-align-items-baseline scroll-overflow-x scroll-hidden-y has-no-scrollbars">
            <RootLocationPill :item="itemContainersComplete[0]"/>
            <ContainerPath v-if="isUserOwnedItemOrBorrowed(item,$store) || VUE_APP_SHOW_CONTAINERPATH_TO_NON_OWNERS == 'true'" :containerArray="itemContainersComplete"/>
          </div>
          <div v-else>
            <RootLocationPill :item="item"/>
          </div>

        </div>
        <div class="listitemremovebutton" v-if="!isMobile() && removebutton != false"  @click.stop.prevent="$emit('swipeRemove')">
          <Icon
            class="is-size-4 mr-5"
            classOverwrite="has-text-purple-1"
            :icon="['fa', 'trash']"
          />
        </div>
        
   </div>
</template>

<script>

import ContainerPath from '@/components/Location/ContainerPath.vue';
import RootLocationPill from '@/components/Location/RootLocationPill.vue';
import {useStore} from 'vuex';
import {onMounted,ref} from '@vue/runtime-core'

import addLazyLoadTrigger from '@/../lazyloader.js'
import {itemLendingDirection,isUserOwnedItem,isUserOwnedItemOrBorrowed,isMobile} from '@/helpers.js';

export default {
  name: "ListItem",
  props: ["item","showCheckboxes","isChecked","swipetoremove","removebutton"],
  emits: ["swipeRemove"],
  components: {
    RootLocationPill,
    ContainerPath
    },
  setup(props,{emit}) {
    const store = useStore();
    const BASEURL = process.env.VUE_APP_API_URL;  

    const VUE_APP_SHOW_CONTAINERPATH_TO_NON_OWNERS = process.env.VUE_APP_SHOW_CONTAINERPATH_TO_NON_OWNERS;

    const okay2load = ref(null);

    const itemContainersComplete = ref(props.item['location.insideofArray']?.map((i)=>{return {id:i} }))
    
    const okay2loadCallback = async () => {
        okay2load.value = true;

        if (!props.item['location.insideofArray']){
          return 
        }
        let allpromises = [];

        for (let id of props.item['location.insideofArray']){
          allpromises.push(store.dispatch('getItem',id).then((res)=>{
            if (res instanceof Error){return {id,name:'?',error:res}}
            return res
          }))
        }

        Promise.all(allpromises).then((res)=>{
          itemContainersComplete.value = res;
        })
      
    }

    const nextSwipeTriggersRemove = ref(false);
    
    const handleSwipeLeft = (e)=>{
      if (props.swipetoremove && nextSwipeTriggersRemove.value){
        console.log("LISTITEM SWIPE LEFT",e);
        nextSwipeTriggersRemove.value = false;
        return
      }
    }

    const handleSwipeRight = ()=>{
      if (props.swipetoremove && !nextSwipeTriggersRemove.value){
        nextSwipeTriggersRemove.value = true;
        return
      }
      if(props.swipetoremove){
        emit("swipeRemove")
      }
    }

    const root = ref(null);    
    onMounted(() => {
        addLazyLoadTrigger(root, okay2loadCallback);  
    })


    return {VUE_APP_SHOW_CONTAINERPATH_TO_NON_OWNERS,baseurl: BASEURL,itemContainersComplete,okay2load,root,itemLendingDirection,isUserOwnedItem,isUserOwnedItemOrBorrowed,handleSwipeRight,handleSwipeLeft,nextSwipeTriggersRemove,isMobile};
  },
};

</script>

