<template>

  <div class="addDing is-full-height is-overflow-y-scroll" v-if="item" ref="root">

  <Modal 
      v-if="deleteModal.visible"
      :modal="deleteModal"
      @closeModal="deleteModal.visible = false"
      :cssClasses="{'is-full-height': true}"  
  >
      <template v-slot:header>
          DELETE ITEM
      </template>
      <template v-slot class="is-full-width">
          <div class="has-background-dark has-text-light is-full-width is-full-height">
              <h1 class="is-size-5 m-2"> Are you sure?</h1>
          </div>
      </template>
      <template v-slot:footer>
          <button class="button has-background-pink-1 has-text-light" @click="handleDeleteDing">DELETE</button>
          <button class="button has-background-pink-1 has-text-light" @click="deleteModal.visible = false">CANCEL</button>
      </template>
  </Modal>


  <Modal 
      v-if="borrowModal.visible"
      :modal="borrowModal"
      @closeModal="borrowModal.visible = false"
      :cssClasses="{'is-full-height': true}"  
  >
      <template v-slot:header>
          CONTACT ITEM OWNERs
      </template>
      <template v-slot class="is-full-width">
          <div class="has-background-light is-full-width is-full-height">
              <h1 class="is-size-5 m-2"> Contact the item's owners:</h1>
              <div class="m-1 is-full-width" v-for="owner in borrowModal.contactDetails" :key="owner.userid">
                <p>Username: <b>{{owner.userid}}</b></p>
                <p v-if="owner.public_contact_details">Contact Details: <b>{{owner.public_contact_details}}</b></p>
                <p v-else>sadly this user did not provide any details on how to get in contact</p>
                <hr>
              </div>
            
          </div>
      </template>
      <template v-slot:footer>
          <button class="button has-background-pink-1 has-text-light" @click="borrowModal.visible = false">CANCEL</button>
      </template>
  </Modal>


  <Modal 
      v-if="handoverModal.visible"
      :modal="handoverModal"
      @closeModal="handoverModal.visible = false"
      :cssClasses="{'is-full-height': true}"  
  >
      <template v-slot:header>
          LENT OUT ITEM
      </template>
      <template v-slot>
          <div class="has-background-light is-full-width is-full-height">
              <h1 class="is-size-5 m-2">Enter (valid) username to handover to user</h1>
              
              <input v-model="handoverReceivingUser" class="input" type="text" placeholder="userid of user to give item to">
              
          </div>
      </template>
      <template v-slot:footer>
          <button class="button has-background-pink-1 has-text-light" @click="handleHandoverClicked">HANDOVER</button>
          <button class="button has-background-pink-1 has-text-light" @click="handoverModal.visible = false">CANCEL</button>
      </template>
  </Modal>

  <Modal 
      v-if="takeBackModal.visible"
      :modal="takeBackModal"
      @closeModal="takeBackModal.visible = false"
      :cssClasses="{'is-full-height': true}"  
  >
      <template v-slot:header>
        TAKE BACK ITEM?
      </template>
      <template v-slot>
      </template>
      <template v-slot:footer>
          <button class="button has-background-pink-1 has-text-light" @click="handleTakeBackClicked">TAKE BACK</button>
          <button class="button has-background-pink-1 has-text-light" @click="takeBackModal.visible = false">CANCEL</button>
      </template>
  </Modal>

    <div class="basket-button-container item-button-container is-flex is-flex-direction-column is-align-items-flex-end">
        <button class="button is-rounded" @click="detailMenuVisible=!detailMenuVisible">
          <Icon class="is-size-6 has-text-light" :icon="['fa', 'ellipsis-h']"/>
        </button>
        <div v-if="detailMenuVisible && item.id" class="basketMenu is-flex is-flex-direction-column is-align-items-flex-end mt-7">
           <button v-if="isOwnedByUserOrBorrowed" class="button is-rounded is-size-7 has-background-pink-1 has-text-white" @click="$store.dispatch('add2Cart',{items:[item]})">ADD TO BASKET</button>
           <button v-if="isAuthenticatedUser()" class="button is-rounded is-size-7 has-background-pink-1 has-text-white" 
                @click="$router.push({path:'/SearchItems',query:{rootContainer:item.id}})">SEARCH INSIDE</button>
          <button v-if="isOwnedByUser" class="button is-rounded is-size-7 has-background-pink-1 has-text-white"
            @click="$router.push('/AddDing?insideof='+item.id)">ADD ITEMS</button>
          
          <button v-if="!isOwnedByUserOrBorrowed" class="button is-rounded is-size-7 has-background-pink-1 has-text-white" @click="handleBorrowClicked">BORROW</button>
          <button v-else-if="isOwnedByUser && lentOrBorrowed == 'LENT'" class="button is-rounded is-size-7 has-background-pink-1 has-text-white"  @click="takeBackModal.visible = true">TAKE BACK</button>
          <button  v-else-if="!isOwnedByUser && lentOrBorrowed == 'BORROWED'" class="button is-rounded is-size-7 has-background-pink-1 has-text-white"  @click="handleBorrowClicked">RETURN TO OWNER</button>
          <button  v-else class="button is-rounded is-size-7 has-background-pink-1 has-text-white"  @click="handoverModal.visible = true">LENT OUT</button>

          <button v-if="isOwnedByUser" class="button is-rounded is-size-7 has-background-pink-1 has-text-white" 
                @click="handleEditDing">EDIT</button>
          <button v-if="isOwnedByUser" class="button is-rounded is-size-7 has-background-pink-1 has-text-white"
            @click="$router.push('/RESERVE')">RESERVE</button>
          <button v-if="isOwnedByUserOrBorrowed" class="button is-rounded is-size-7 has-background-pink-1 has-text-white"
            @click="$router.push('/Move')">MOVE</button>
          <button v-if="isAuthenticatedUser()" class="button is-rounded is-size-7 has-background-pink-1 has-text-white" 
            @click="$router.push('/QRCodeDisplay?singleItem=true')">QR CODE</button>
          <button v-if="isAuthenticatedUser()" @click="handleCreateCopyClicked" class="button is-rounded">CREATE COPY</button> 
          <button v-if="isOwnedByUser" class="button is-rounded is-size-7 has-background-dark has-text-white" @click="deleteModal.visible = true">DELETE</button>
        </div>
    </div>
    <div class="gallery">
      <back-button />
      <div class="add-photos-button is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-relative is-z-2">
        <DingGallery class="is-absolute img-fit-cover" :imageArray="item.pictures" />
      </div>
    </div>
     
    <div class="is-flex is-flex-direction-column pl-5 is-full-width">
        <h1 class="is-full-width m-1 is-size-4 is-text-align-start">
            {{item.name}}
        </h1>

        <PossessionPills :item="item"/>
        
        <div class="is-full-width m-1 is-flex is-flex-direction-row">
            <RootLocationPill :item="item"/>
            <div v-if="item['location.insideofArray']"
                class="m-1 is-flex is-flex-direction-row is-align-items-baseline scroll-overflow-x scroll-hidden-y has-no-scrollbars">
                <ContainerPath :containerArray="containerArray"/>
            </div>  
        </div>
        <div v-if="itemContents.length > 0" class="is-flex is-flex-direction-row is-align-items-baseline scroll-overflow-x scroll-hidden-y has-no-scrollbars" :class="isMobile() ?'':'is-flex-wrap-wrap'">
          contains:
          <div v-for="content in itemContents" :key="content.id" 
                class="m-1 ">
            <div>
              <Pill :tag="content.name" @click="$router.push('/Detail?id='+content.id)" hideDeleteButton=true />
            </div>
            
          </div>
          
        </div>

        <div class="is-full-width" v-if="item.count">
            <Pill class="m-1" :tag="'count:'+item.count" hideDeleteButton="true"/> 
        </div>
        
        <div> <hr class="spacer"> </div>

        <div v-if="!isAuthenticatedUser()">
          <p :if="item?.['location.geoJSON']">
                <a class="button is-primary" target="_blank" :href="'https://geojson.io/#data=data:application/json,'+item?.['location.geoJSON']">open map of location / area</a>
            </p>
        </div>

        <div class="is-text-align-start mb-2 mt-1">
            {{item.description}}
        </div>

        <div v-if="lengthtags && lengthtags.length > 0" >
            <div class="is-full-width broad-input">
                <h1 class="is-text-align-start is-size-5 is-full-width">length</h1>
            </div>
            <div class="is-full-width is-flex is-flex-wrap-wrap">
                <div v-for="length in lengthtags" :key="length">
                    <Pill class="m-1" :tag="length.split('length:')[1]" hideDeleteButton="true"/>
                </div>
                
            </div>
        </div>
        

        <div v-if="sizetags && sizetags.length > 0" >
            <div class="is-full-width broad-input">
                <h1 class="is-text-align-start is-size-5 is-full-width">size</h1>
            </div>
            <div class="is-full-width is-flex is-flex-wrap-wrap">
                <div v-for="size in sizetags" :key="size">
                    <Pill class="m-1" :tag="size.split('size:')[1]" hideDeleteButton="true"/>
                </div>
                
            </div>
        </div>

        <div class="is-flex">
          <div v-if="item.length" >
              <div class="is-full-width broad-input">
                  <h1 class="is-text-align-start is-size-5 is-full-width">length</h1>
              </div>
              <div class="is-full-width">
                      <Pill class="m-1" :tag="item.length + 'cm'" hideDeleteButton="true"/> 
              </div>
          </div>
          <div v-if="item.width" class="m-1"> x </div>
          <div v-if="item.width" >
              <div class="is-full-width broad-input">
                  <h1 class="is-text-align-start is-size-5 is-full-width">width</h1>
              </div>
              <div class="is-full-width">
                      <Pill class="m-1" :tag="item.width + 'cm'" hideDeleteButton="true"/> 
              </div>
          </div>
          <div  v-if="item.depth" class="m-1"> x </div>
          <div v-if="item.depth" >
              <div class="is-full-width broad-input">
                  <h1 class="is-text-align-start is-size-5 is-full-width">height</h1>
              </div>
              <div class="is-full-width">
                      <Pill class="m-1" :tag="item.depth + 'cm'" hideDeleteButton="true"/> 
              </div>
          </div>
        </div>
        

        <div v-if="item.weight" >
            <div class="is-full-width broad-input">
                <h1 class="is-text-align-start is-size-5 is-full-width">weight</h1>
            </div>
            <div class="is-full-width">
                    <Pill class="m-1" :tag="item.weight + 'kg'" hideDeleteButton="true"/> 
            </div>
        </div>

        <div v-if="item.tags && item.tags.length > 0">
            <div class="is-full-width broad-input">
                <h1 class="is-text-align-start is-size-5 is-full-width">Specifics / Tags</h1>
            </div>
            <div class="is-full-width is-flex is-flex-wrap-wrap">
                <div v-for="tag in item.tags" :key="tag">
                    <Pill class="m-1" v-if="specificTagsDisplayFilter({tag})" :tag="tag" hideDeleteButton="true" 
                      @onTagClick='$router.push(`/SearchItems?tags=${tag}`)'/> 
                </div>
            </div>
        </div>  

        <div v-if="item.value && item.value !== '$0.00'" >
            <div class="is-full-width broad-input">
                <h1 class="is-text-align-start is-size-5 is-full-width">value</h1>
            </div>
            <div class="is-full-width">
              <Pill class="m-1" :tag="item.value.replace('$','€')" hideDeleteButton="true"/> 
            </div>
        </div>


        <div v-if="item.price  && item.price !== '$0.00'" >
            <div class="is-full-width broad-input">
                <h1 class="is-text-align-start is-size-5 is-full-width">price</h1>
            </div>
            <div class="is-full-width">
              <Pill class="m-1" :tag="item.price.replace('$','€')" hideDeleteButton="true" />
            </div>
        </div>
    
    </div>
    <div class="is-menubar-height"></div>
    
  </div>
  
</template>

<script>
import DingGallery from '@/components/Image/DingGallery.vue'

import Pill from '@/components/Template/Pill';
import Icon from '@/components/Template/Icon';
import ContainerPath from '@/components/Location/ContainerPath'
import RootLocationPill from '@/components/Location/RootLocationPill'
import PossessionPills from '@/components/ItemForm/PossessionPills';
import Modal from '@/components/Template/Modal';

import { ref, onMounted, onUnmounted ,computed, watch} from "@vue/runtime-core";
import { useStore } from "vuex";
import BackButton from '@/components/Template/BackButton.vue';
import { useRouter } from 'vue-router';

import {itemLendingDirection,isUserOwnedItem,isUserOwnedItemOrBorrowed,isMobile} from '@/helpers.js';

export default {
  name: 'ItemDetail',
  props:['item'],
  components: {
    DingGallery,
    BackButton,
    Pill,
    ContainerPath,
    RootLocationPill,
    Icon,
    PossessionPills,
    Modal,
  },
  setup(props){
    const store = useStore();
    const router = useRouter();

    const weight = ref([props.item.weight]);

    const sizetags = computed(()=>{return props.item.tags?.filter((t)=>t.startsWith("size:"))})

    const lengthtags = computed(()=>{return props.item.tags?.filter((t)=>t.startsWith("length:"))})

    const containerArray = ref(props.item['location.insideofArray']?.map((i)=>{return {id:i} }))

    const detailMenuVisible = ref(false);

    const itemContents = ref([]);

    const lentOrBorrowed = computed(()=>itemLendingDirection(props.item,store))

    const isOwnedByUser = computed(()=>isUserOwnedItem(props.item,store))

    const isOwnedByUserOrBorrowed = computed(()=>isUserOwnedItemOrBorrowed(props.item,store))

    const reservations = ref(null);

    watch(computed(()=>props.item),()=>{
      console.log("item changed");
      fillItemContents();
      fillContainerArray();
    })

    const fillReservations = async () => {
      let res = await store.dispatch("getReservations",{item:props.item.id});
      reservations.value = res.reservations;
    }

    // TODO: refactor into helper.js
    const fillContainerArray = () => {
        if (!props.item['location.insideofArray']){return}

        let allpromises = [];
        for (let id of props.item['location.insideofArray']){
          allpromises.push(store.dispatch('getItem',id).then(res=>res instanceof Error ? id : res));
        }
        Promise.all(allpromises).then((res)=>{
          containerArray.value = res;
        })
    }

    const fillItemContents = async () =>{
      let itemContentsReceived = await store.dispatch('getItemContents',props.item.id);
      itemContents.value = itemContentsReceived.sort((a, b) =>a.name > b.name ? 1 : -1)
      fillReservations();
    }

    const handleEditDing = ()=>{
      router.push("/EditDing?id="+props.item.id);
    }

    const deleteModal = ref({visible:false})
    const handleDeleteDing = async ()=>{
      let result = await store.dispatch('deleteItem',props.item.id);
      console.log("item deleted result",result);
      if (result.data?.deleted){
        store.dispatch('removeItemsFromListById',{key:'basket',items:[props.item]})// delete from basket in case its in there
        store.dispatch('removeItemsFromListById',{key:'searchResultItems',items:[props.item]})// delete from searchResults in case its in there
        router.go(-1);
      }
    }

    onMounted(()=>{
      fillItemContents();
      fillContainerArray();
      if (!isAuthenticatedUser()) detailMenuVisible.value = true;
    })

    onUnmounted(()=>{
    })

    const specificTagsDisplayFilter = ({tag})=>{
        if(tag.startsWith('size:') || tag.startsWith('length:')){
          return false
        }
        else{
          return true
        }
    }

    const isAuthenticatedUser = ()=>{
      console.log("isAuth", localStorage.authtoken && localStorage.authtoken != 'null');
      return (localStorage.authtoken && localStorage.authtoken != 'null')
    }

    const itemRootLocation = ref(null)

    const borrowModal = ref({visible:false})
    const handleBorrowClicked = async ()=>{
      borrowModal.value.visible = true;
      borrowModal.value.contactDetails = await store.dispatch('getItemOwnersContactDetails',props.item.id)
    }

    const handoverReceivingUser = ref(null);
    const handoverModal = ref({visible:false})
    const handleHandoverClicked = async ()=>{
      if (!handoverReceivingUser.value){return store.dispatch('triggerToast',{message:'username must be provided!'})}
      let result = await store.dispatch('handoverItem',{item_id:props.item.id,to:[handoverReceivingUser.value]})
      if (result){
        handoverModal.value.visible = false;
        setTimeout(router.go(-1),100)
      }
    }

    const takeBackModal = ref({visible:false})
    const handleTakeBackClicked = async ()=>{
      let result = await store.dispatch('handoverItem',{item_id:props.item.id,to:[ ]})
      if (result){
        takeBackModal.value.visible = false;
        setTimeout(router.go(-1),100)
      }
    }

    async function handleCreateCopyClicked(){
      await store.dispatch("setLastItemAdded",{key:"lastItemAdded",value:props.item})
      router.push('/AddDing?similarItem=true');
    }

    return {
        specificTagsDisplayFilter,
        weight,
        lengthtags,
        sizetags,
        itemRootLocation,
        containerArray,
        detailMenuVisible,
        handleEditDing,
        handleDeleteDing,
        itemContents,
        lentOrBorrowed,isOwnedByUser,isOwnedByUserOrBorrowed,
        handleBorrowClicked, borrowModal,
        handleHandoverClicked, handoverModal,handoverReceivingUser,
        handleTakeBackClicked, takeBackModal,
        deleteModal,
        reservations,
        handleCreateCopyClicked,
        isMobile,
        isAuthenticatedUser
        }
  }
}
</script>

<style scoped>

</style>
